import React, { useState } from "react";
import emailjs from "emailjs-com";
import SEO from "../common/SEO";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from "../common/header/HeaderTopBar";
import HeaderOne from "../common/header/HeaderOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";
import SlipTwo from "../elements/split/SlipTwo";
import Separator from "../elements/separator/Separator";
import AdvanceTab from "../elements/advancetab/AdvanceTab";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import CircleProgressTwo from "../elements/progressbar/CircleProgressTwo";

const FreeDigitalAudit = () => {
  const [message, setMessage] = useState(""); // State to manage message

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7yyrafb",
        "template_nsldnyl",
        e.target,
        "mh2NzoQE6vH5s5-zI"
      )
      .then(
        (result) => {
          setMessage("Submission successful!"); // Update message state
          // Clear message after 5 seconds
          setTimeout(() => setMessage(""), 5000);
        },
        (error) => {
          console.error("Failed to send email:", error.text);
          setMessage("Submission failed. Please try again."); // Update message state
          // Clear message after 5 seconds
          setTimeout(() => setMessage(""), 5000);
        }
      );

    e.target.reset();
  };

  return (
    <>
      <SEO title="Corporate" />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderTopBar />
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>

        {/* Start Slider area  */}
        <div
          className="slider-area slider-style-2 height-950 bg_image"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-5.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-start">
                  <h4 className="subtitle">
                    <span className="theme-gradient">
                      DIGITAL CONSULTING AGENCY
                    </span>
                  </h4>
                  <h1 className="title display-one">
                  Free Digital Audit
                  </h1>

                  <ul className="list-icon">
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{" "}
                      Real-Time Tracking: Monitor your team’s progress on the go
                      with your application.
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{" "}
                      User-Friendly Interface: Intuitive design for smooth
                      navigation and quick inputs.
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>{" "}
                      Enhanced Collaboration: Stay connected and productive
                      wherever your team is located
                    </li>
                  </ul>

                  {/* Updated Search Form */}
                  <form className="search" onSubmit={handleSubmit}>
                    <div className="form-group bd-dark">
                      <input
                        name="message"
                        className="search_input"
                        placeholder="Input Your Website URL"
                        required
                      ></input>
                    </div>
                    <button type="submit" className="btn-default btn-icon">
                      Submit{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </button>
                  </form>

                  {/* Displaying the message */}
                  {message && <p>{message}</p>} {/* Display the message */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider area  */}

        <div className="container text-center">
          <h4 className="title mt--40">
            Accessibility Guidelines & Legislation Tested By:
          </h4>
          <TestimonialThree />
        </div>

        <Separator />

        <div className="container text-center">
          <h4 className="title mt--40">Readability Tested By:</h4>
          <CircleProgressTwo />
        </div>

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Website accessibility compliance ensures equal access for everyone."
                  description="Laws mandate that websites be accessible to individuals with disabilities. An accessible website provides a secure experience for all users and helps avoid legal risks."
                />
              </div>
            </div>
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        <AboutTwo />

        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-counterup-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Clients Feedback."
                  title="Global Clients Around the World."
                  description="At WhatsOn Agency, we are proud to partner with businesses from every corner of the globe. Our mission is to help clients achieve their digital aspirations with creativity, strategy, and innovation."
                />
              </div>
            </div>
            <CounterUpTwo
              column="col-lg-4 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-2"
              textALign="text-center"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* split section start from here */}

        <Separator />
        <SlipTwo />

        <Separator />

        {/* split section end from here */}

        <AdvanceTab />

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>

        {/* End Call To Action Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default FreeDigitalAudit;
