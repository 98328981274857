import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AdvancePricingOne from "../elements/advancePricing/AdvancePricingOne";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";
import Separator from "../elements/separator/Separator";
// import Service from "../elements/service/Service";
import CalltoActionEight from "../elements/calltoaction/CalltoActionEight";
import BlogClassicData from "../data/blog/BlogList.json";
import OfferPricing from "../elements/advancePricing/OfferPricing";
var BlogListData = BlogClassicData.slice(0, 3);

const SpecialOffer = () => {
  return (
    <>
      <SEO title="International Consulting" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-transparent"
        />
        <div
          className="text-center"
          data-black-overlay="1"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-6.png)`,
          }}
        >
          <div className="slider-area slider-style-7 height-750">
            <div className="container">
              <div className="row">
                <div className="">
                  <div className="inner">
                    <h3 className="title-small">
                      Transform Your Digital Presence
                    </h3>
                    <h4 class="subtitle rn-sub-badge">
                      <span class="text-white">
                        Elevate your business with our comprehensive web
                        development and digital marketing solutions
                      </span>
                    </h4>
                    <div className="mt--40">
                      <li>
                        <b>Redefine your digital presence.</b>
                      </li>
                      <li>
                        <b>Achieve unparalleled growth and success.</b>
                      </li>
                      <li>
                        <b>Leverage innovative, tailored strategies.</b>
                      </li>
                      <li>
                        <b>Build strong connections with your audience.</b>
                      </li>
                      <li>
                        <b>Stay ahead in the competitive digital world.</b>
                      </li>
                    </div>

                    <div className="button-group mt--40 mt_sm--20">
                      <a
                        className="btn-default btn-icon round btn-large"
                        target="_blank"
                        href="/service"
                      >
                        Explore Packages{""}
                        <i className="icon">
                          <FiArrowRight />
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Start Slider area  */}

        {/* End Slider area  */}

        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-pricingtable-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <AdvancePricingOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* Start Elements Area  */}

        <SectionTitle textAlign="text-center" title="Our Services" />
        <div class="text-center mb-4 text-black">
          <p>Specialized solutions with 30 days support included</p>
        </div>
        <OfferPricing />
        {/* End Elements Area  */}

        <Separator />
        <Separator />
        {/* Start Call To Action Area  */}
        <div className="container">
          <div className="rwt-callto-action-area rn-section-gapBottom mt--60">
            <div className="wrapper">
              <CalltoActionEight />
            </div>
          </div>
        </div>

        {/* End Call To Action Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default SpecialOffer;
